export class SupplierFilter {
	supplierTypes: SupplierType[] = new Array<SupplierType>();
	eventTypes: EventType[] = new Array<EventType>();
}

export class Option {
	label!: string; 
	value!: string; 
}


export class Filter {
	label!: string;
	options: Option[] = new Array<Option>(); 
	parameter!: string;
	hide!: string;
}


export class SupplierType {	
	label!: string;
	filters: Filter[] = new Array<Filter>();  
	parameter!: string;
	value!: string;
}

export class EventType {
	label!: string;
	value!: string;
}

export class SelectedFilter {
	parameter!: string;
	value!: string;
}