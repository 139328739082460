
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Filter, SelectedFilter } from '../models/supplier-filter';

@Component
export default class FilterOption extends Vue {

  //#region Fields
  // ===================================================================
  // Fields
  // ===================================================================
  @Prop() readonly filter!: Filter;
  @Prop() selectorId!: string;
  selectedFilterKey = '';
  addDefaultOption = false;
  // ===================================================================
  //#endregion Fields

  //#region Life Cycle And Helper Methods
  // ===================================================================
  // Life Cycle And Helper Methods
  // ===================================================================
  // Once the vue created, bind the selected filter
  created() {
    this.bindSelectedFilter();
  }    

  // Bind the selected filter key from the filter options first value
  bindSelectedFilter() {
    this.selectedFilterKey = "-1";
  }

  // Bind the selected filter key from the filter options first value
  getSelectedFilter(): SelectedFilter {            
    const selectedFilter: SelectedFilter = new SelectedFilter();
    selectedFilter.parameter = this.filter.parameter;
    selectedFilter.value = this.selectedFilterKey;      
    return selectedFilter;
  }

  // ===================================================================
  //#endregion Life Cycle And Helper Methods
}
