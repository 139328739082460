













































  import { Component, Vue } from 'vue-property-decorator';
  import { SupplierFilter, Filter } from '../models/supplier-filter';
  import { supplierFilterServie } from '../services/supplier-filter.servie';
  import FilterOption from './FilterOption.vue';
  import { IFilterOption } from '../models/filter-option';

  @Component({
    components: {
      FilterOption
    },
  })
  export default class SupplierSearchFilter extends Vue {

    //#region Fields
    // ===================================================================
    // Fields
    // ===================================================================
    public supplierFilter: SupplierFilter = new SupplierFilter();
    public selectedSupplierType = '';
    public selectedEventType = '';
    public filters: Filter[] = new Array<Filter>();
    private params!: URLSearchParams;
		private besUrl = `${process.env.VUE_APP_SUPPLIER_SEARCH_URL}/supplier-search`;
    private readonly pageSizeKey = "pagesize";
    private readonly pageSizeValue = 10;
    // ===================================================================
    //#endregion Fields

    //#region Life Cycle And Helper Methods
    // ===================================================================
    // Life Cycle And Helper Methods
    // ===================================================================
    // Once the vue created, get the Supplier Filters by calling the vue service
    created() {
      this.getSupplierFilters();
    }

    // Once the select is changed, then rebind the list
    // as well as bind the selected filter to the filter option child component
    // Finally, Add or update the search url parameter
    onSupplierTypeChange() {
      this.bindFilters();

      // wait till the dom cycle has been finished, then call the bindSelectedFilter for each children
      // using arrow function to not create a new scope and using the class fields
      Vue.nextTick(() => {
        const children = this.$refs.childFilterOption as IFilterOption[];
        children.forEach(function (child) {
          child.bindSelectedFilter();
        });
      });
    }

    // Call the service to get the SupplierFilters
    getSupplierFilters() {
      return supplierFilterServie.getSupplierFilters().then(response => (
        this.supplierFilter = response.data,
        this.bindSupplierFilters()        
      ));
    }

    // Assign the selected supplierType & selected EventType
    // Then, Bind the filters based on the selected supplier type
    // Finally, add Ur Parameters for the supplier filters such as supplier type, events and so on
    bindSupplierFilters() {
      this.selectedSupplierType = "-1";
      this.selectedEventType = "-1" ;
      this.bindFilters();            
    }

    // Bind the filters based on the selected supplier type
    // as well as filter the ones that needs to be shown (not-hidden)
    bindFilters() {
      this.filters = this.supplierFilter !== null ?
        this.supplierFilter.supplierTypes.flatMap(o => o.value == this.selectedSupplierType ? o.filters : []).filter(o => o.hide.toLowerCase() != 'true') : [];
    }

    // Bind the url parameters based on the selectors selected value
    // if the selected value is not the default value which is -1 then add the selected value to Url search parameters
    bindUrlParameters() {
      this.params = new URLSearchParams();

      //if the selected supplier type been specified and not the default one, then add it to the url paramter
      const currentsupplierType = this.supplierFilter.supplierTypes.find(o => o.value == this.selectedSupplierType)
      if (currentsupplierType) {
        this.addOrUpdateParameters(currentsupplierType.parameter, this.selectedSupplierType.toLocaleLowerCase());
      }

      // If the selectedEventType is not the default selected value, then add it to the url parameters
      if (this.selectedEventType != "-1") {
        this.addOrUpdateParameters(this.selectedEventType, true);
      }

      // using arrow function to not create a new scope and using the class fields
      // then Add url query string parameters for each child in the FilterOption if it has been specified,
      const children = this.$refs.childFilterOption as IFilterOption[];
      if (children && children.length > 0) {
        children.forEach((child) => {
          const selectedFilter = child.getSelectedFilter();
          if (selectedFilter.value != "-1") {
            this.addOrUpdateParameters(selectedFilter.parameter, selectedFilter.value.toLocaleLowerCase());
          }
        });
      }
      // Add the page size and page size vaue to the parame objet
      this.addOrUpdateParameters(this.pageSizeKey, this.pageSizeValue);
    }

    // Add or update the Url search parameters
    // paramKey: is the key for the search query paramters
    // paramValue: the value for each key 
    addOrUpdateParameters(paramKey: string, paramValue: any) {
      if (this.params.has(paramKey)) {          
        this.params.set(paramKey, paramValue);       
      }
      else {
        this.params.append(paramKey, paramValue);        
      }
    }    

    // Redirect the user to the BE Sydney website along with the search parameters
    redirctToSupplierSearch() {
      this.bindUrlParameters();  
			location.href = `${this.besUrl}/results?${this.params.toString()}`;
    }
    // ===================================================================
    //#endregion Life Cycle And Helper Methods
  }
