import axios from 'axios';
import { SupplierFilter } from '../models/supplier-filter';

const api = process.env.VUE_APP_API_URL;

class SupplierFilterServie {

  //#region REST API Methods
  // ===================================================================
  // REST API Methods
  // ===================================================================
  getSupplierFilters() {
    return axios.get<SupplierFilter>(`${api}/Filters`);
  }
  // ===================================================================
  //#endregion REST API Methods

}

// Export a singleton instance in the global namespace
export const supplierFilterServie = new SupplierFilterServie();
