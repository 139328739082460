




import { Component, Vue } from 'vue-property-decorator';
  import SupplierSearchFilter from './components/SupplierSearchFilter.vue';

@Component({
  components: {
    SupplierSearchFilter
  },
})
export default class SupplierSearch extends Vue {
  constructor() {
    super();
  }
}
